import { Currency } from '../../../utils/currency'

export interface ProductForSaleFormFields {
  activeProductIds: Set<string>
  displayType: string
}
export interface MerchantAuthStatus {
  signupStatus?: boolean
  autoTaxStatus?: boolean
}

export interface PromotedItemProps {
  link: string
  currency: Currency
  title: string
  description?: string
  price: number //in cents
  salePrice: number | null
  promoCode?: string | null
}

export interface StripeProduct {
  id: string
  description: string | null
  name: string
  images: string[]
  price: {
    unitCostCents: number | null
    currency: string
  }
}
export interface ProductFormProps {
  product: StripeProduct
  handleClose: () => void
  setValue: (
    name: string,
    value: any,
    config?:
      | Partial<{
          shouldValidate: boolean
          shouldDirty: boolean
        }>
      | undefined
  ) => void
}
export interface ProductFormState {
  title: string
  setTitle: (_0: string) => void
  description: string | null
  setDescription: (_0: string) => void
  currency: Currency
  setCurrency: (_0: Currency) => void
  price: number | null
  setPrice: (_0: number | null) => void
  handleSave: () => void
  handleDelete: () => void
  errors: Record<string, string | null>
}

export enum CommerceFormFields {
  DISPLAY_TYPE = 'displayType',
  ACTIVE_PRODUCT_IDS = 'activeProductIds'
}
