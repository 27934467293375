import { ContextMenu, ContextMenuAction, DropdownMenu } from '@dtx-company/shared-components/src'
import { FC, useMemo, useState } from 'react'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { StripeProduct } from './types'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { checkmarkFlowBlue } from '@app/code/src/constants/assets'

export const ProductSelector: FC<{
  stripeProducts?: StripeProduct[]
  activeProductIds: Set<string>
  handleClick: (id: string) => void
  disabled: boolean
}> = ({ stripeProducts, handleClick, disabled, activeProductIds }) => {
  const [open, setOpen] = useState(false)
  const formattedActions = useMemo(
    () =>
      stripeProducts?.map(prod => ({
        id: prod.id,
        label: prod.name,
        labelProps: {
          fontWeight: activeProductIds.has(prod.id) ? 'bold' : undefined
        },
        imgProps: { height: '40px', width: '40px', ml: '4px' },
        img: prod.images?.[0] ? { src: prod.images[0], alt: prod.name } : undefined,
        action: () => {
          handleClick(prod.id)
        },
        endImg: activeProductIds.has(prod.id)
          ? { src: checkmarkFlowBlue, alt: 'checked' }
          : undefined
      })) as ContextMenuAction[],
    [handleClick, stripeProducts, activeProductIds]
  )
  if (!formattedActions) return null
  return (
    <DropdownMenu
      disableAutoClose={true}
      disabled={disabled}
      header={
        <>
          {activeProductIds.size === 0 ? <Image src={'/icons/plus.svg'} /> : <></>}
          <Spacer mr="8px" />
          <Text color="primary.flowBlue" variant="button/general">
            {activeProductIds?.size > 0 ? 'Edit Products' : 'Add a Product'}
          </Text>
        </>
      }
      borderColor={'primary.flowBlue'}
      padding="12px 34px 12px 12px"
      maxWidth="100%"
      justifyContent="center"
      backgroundColor="secondary.background"
      strokeColor="primary.flowBlue"
      borderRadius={open ? '4px 4px 0px 0px' : '4px'}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      <ContextMenu
        position="absolute"
        zIndex={1}
        borderColor="primary.flowBlue"
        borderTop={'none'}
        borderRadius={'0px 0px 4px 4px'}
        width="calc(100% - 1px)"
        backgroundColor="secondary.background"
        actions={formattedActions}
        enableMiddleBorders
      />
    </DropdownMenu>
  )
}
