import { Box } from '../../atoms/Box/index'
import { ContextMenuLinkAdornmentProps, ContextMenuProps, OptionalLinkProps } from '../../../types'
import { FC, PropsWithChildren, cloneElement } from 'react'
import { Icon } from '../../atoms/Icon/index'
import { MenuAction, MotionContainer, MotionUnorderdList } from './styles'
import { OptionalLink } from '../../atoms/OptionalLink/index'
import { Text } from '../../atoms/Text/index'

const ContextMenuLinkAdornment: FC<ContextMenuLinkAdornmentProps> = ({
  img,
  imgProps,
  endImg,
  icon,
  badge
}) => {
  const className = endImg ? 'end-image' : 'image'

  if (icon)
    return cloneElement(icon, {
      className,
      style: { marginLeft: '17px', marginRight: '17px' }
    })

  if (!img) return <></>

  if (badge) return badge

  return (
    <Icon
      className={className}
      src={img.src}
      alt={img.alt}
      height={img.height || 20}
      width={img.width || 58}
      {...imgProps}
    />
  )
}

const ContextMenuItemWrapper: FC<PropsWithChildren<Partial<OptionalLinkProps>>> = ({
  href,
  NextLink,
  children
}) => {
  return href ? (
    <OptionalLink href={href} NextLink={NextLink}>
      {children}
    </OptionalLink>
  ) : (
    <>{children}</>
  )
}

export const ContextMenu = ({
  actions,
  center = false,
  leftAlign = false,
  enableMiddleBorders = false,
  dynamicHeight = false,
  maxHeight,
  NextLink,
  fontFamily,
  fontSize,
  menuMotionVariant,
  menuItemsMotionVariant,
  contextMotionVariant,
  afterOnClick,
  ...rest
}: ContextMenuProps): JSX.Element => {
  return (
    <MotionContainer
      overflowY={maxHeight ? 'scroll' : 'visible'}
      maxHeight={maxHeight}
      aria-label={menuMotionVariant ? 'motion-menu' : `menu`}
      {...contextMotionVariant}
      {...rest}
    >
      <MotionUnorderdList
        variants={menuMotionVariant}
        aria-label={menuMotionVariant ? 'motion-menu-list' : `menu-list`}
      >
        {actions.map(action => {
          const text =
            action.hideOverflowLength !== undefined &&
            action.hideOverflowLength >= 0 &&
            action.label.length > action.hideOverflowLength
              ? action.label.substring(0, action.hideOverflowLength).concat('...')
              : action.label
          return (
            <MenuAction
              key={action.id}
              onClick={e => {
                action.action?.(e)
                afterOnClick?.()
              }}
              center={center}
              leftAlign={leftAlign}
              borderBottom={action.borderBottom}
              borderTop={action.borderTop}
              enableMiddleBorders={enableMiddleBorders}
              variants={menuItemsMotionVariant}
              dynamicHeight={dynamicHeight}
            >
              <ContextMenuItemWrapper href={action.href} NextLink={NextLink}>
                <>
                  <ContextMenuLinkAdornment
                    img={action.img}
                    imgProps={action.imgProps}
                    icon={action.icon}
                  />
                  <Text
                    className="label"
                    fontFamily={fontFamily}
                    fontSize={fontSize || 16}
                    {...action.labelProps}
                  >
                    {text}
                  </Text>
                  {action.endImg && !action.badge && (
                    <ContextMenuLinkAdornment
                      img={action.endImg}
                      imgProps={action.endImgProps}
                      endImg={action.endImg}
                      icon={action.icon}
                      badge={action.badge}
                    />
                  )}
                  {action.badge && !action.endImg && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      ml="auto"
                      mr={17}
                    >
                      {action.badge}
                    </Box>
                  )}
                </>
              </ContextMenuItemWrapper>
            </MenuAction>
          )
        })}
      </MotionUnorderdList>
    </MotionContainer>
  )
}
