import {
  ContextMenuListProps,
  ContextMotionVariantProps
} from '../../../types/molecules/contextmenu.types'
import { border, color, layout, position, shadow, space, zIndex } from 'styled-system'
import { m as motion } from 'framer-motion'
import { theme } from '../../../theme'
import isPropValid from '@emotion/is-prop-valid'
import styled from 'styled-components'

const borderTopMode = `
  border-top: 1px solid #dedede;
`

const borderBottomMode = `
  border-bottom: 1px solid #dedede
`

const actionBase = `
  display: flex;
  align-items: center;
  min-height: 48px;
  position: relative;
  height: 48px;
  width: 100%;
  cursor: pointer;
  :first-child {
    height: 56px;
  }
  :last-child {
    height: 56px;
  }
  .image {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .end-image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
  }
  .label {
    position: absolute;
    left: 58px;
    line-height: 32px;
  }
  :hover {
    background-color: #f5f5f5;
    color: #000000;
    text-decoration: none;
  }
  :link, :active, :visited, :hover {
    text-decoration: none;
  }
`

const firstAndLastMode = `
  :first-child {
    ${borderBottomMode}
  }
  :last-child {
    ${borderTopMode}
  }
`

const middleBorderMode = `
  ${borderTopMode}
  :first-child {
    border-top: none;
  }
`

const centerMode = `
  justify-content: center;
  .label {
    position: static;
  }
  .image {
    width: unset;
    margin-right: 10px;
  }
`

const leftAlignMode = `
  position: relative;
  padding: 0 16px;
  box-sizing: border-box;
  .label {
    position: relative;
    left: 0;
  }
`

export const MenuAction = styled(motion.li).withConfig({
  shouldForwardProp: prop => isPropValid(prop)
})<{
  center?: boolean
  leftAlign?: boolean
  borderTop?: boolean
  borderBottom?: boolean
  enableMiddleBorders?: boolean
  dynamicHeight?: boolean
  href?: string
}>`
  ${({ href }) => !href && actionBase}
  a {
    ${actionBase}
    ${({ center }) => center && centerMode}
  }
  ${({ dynamicHeight }) => dynamicHeight && 'height:unset!important;'}
  ${({ center }) => center && centerMode}
  ${({ enableMiddleBorders }) => enableMiddleBorders && middleBorderMode}
  ${({ leftAlign }) => leftAlign && leftAlignMode}
  ${({ borderTop, enableMiddleBorders }) => borderTop && !enableMiddleBorders && borderTopMode}
  ${({ borderBottom, enableMiddleBorders }) =>
    borderBottom && !enableMiddleBorders && borderBottomMode}
`

export const MotionContainer = styled(motion.div).withConfig({
  shouldForwardProp: prop => isPropValid(prop)
})<ContextMenuListProps & ContextMotionVariantProps>`
  width: 272px;
  border: 1px solid #dedede;
  border-radius: 4px;
  background-color: ${theme.colors.primary.white};
  display: flex;
  flex-direction: column;
  z-index: 0;
  ${space}
  ${layout}
  ${position}
  ${zIndex}
  ${border}
  ${color}
  ${shadow}
`
export const MotionUnorderdList = styled(motion.ul)`
  list-style: none;
  padding: 0;
  margin: 0;
`
