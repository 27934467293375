import { OptionalLinkProps } from '../../../types'

export const OptionalLink = ({
  NextLink,
  href,
  id,
  arrayKey,
  children,
  onClick,
  ref,
  ...rest
}: OptionalLinkProps): JSX.Element => {
  if (NextLink) {
    return (
      <NextLink onClick={onClick} id={id} href={href} key={arrayKey} {...rest}>
        {children}
      </NextLink>
    )
  }

  return (
    <a onClick={onClick} key={arrayKey} id={id} href={href as string} {...rest}>
      {children}
    </a>
  )
}
