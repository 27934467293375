import * as React from 'react'
import * as icons from '../../../assets/icons'
import { Box, CenterBox } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { BoxProps } from '@dtx-company/shared-components/src'
import { ButtonLink } from '@dtx-company/inter-app/src/utils/links'
import { COMMERCE_WORKER_ENDPOINT } from '@dtx-company/true-common/src/constants/endpoints'
import { CURRENCIES, formatCurrency, toDollars } from '../../../utils/currency'
import {
  CommerceFormFields,
  MerchantAuthStatus,
  ProductForSaleFormFields,
  StripeProduct
} from './types'
import { DefaultManager, ManagerProps } from '../components/Manager/Manager'
import { DisplayToggle } from '../components/DisplayToggle'
import { EditLinkCardEndAdornment } from '../EditLinkUtils'
import { FieldError, useForm } from 'react-hook-form-deprecated'
import { FlowpageProps } from '../types/FlowpageProps'
import { FormProps, StyledForm } from '../components/Layout'
import { FormTitle } from '../components/Title'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { LinkStyleGated } from '../components/LinkStyle/LinkStyleGated'
import { Marketplace } from '@dtx-company/shared-components/src/foundation/Icons/Flowpage/Widgets/Marketplace'
import { ProductSelector } from './ProductSelector'
import { SettingsAndSaveLink } from '../components/Settings'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Spinner } from '@dtx-company/shared-components/src/components/atoms/Spinner/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { defaultWidgetLink } from '../../../constants'
import { getLinkCardStyles } from '../../../components/flowpage/FlowpageLinkCard/utils/getLinkCardStyles'
import { getValidatedActionData, getValidatedActionDataOrThrow } from '../typeUtils'
import { removeNull } from '@dtx-company/true-common/src/utils/objects'
import { stopPropagationEvents } from '../../../components/profile/PageEditor/components/LinkEditDrawer/LinkEditDrawer.utils'
import { stringify } from 'query-string'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useCallback, useEffect, useMemo } from 'react'
import { useCurrentPage } from '@dtx-company/inter-app/src/redux/slices/utils/currentPage'
import { useFlowpageAnalyticsCollector } from '../../../hooks/useFlowpageAnalyticsCollector'
import { useFlowpageTheme } from '../../../components/profile/Customize/hooks'
import { useLinkThemeState } from '../components/LinkStyle/hooks/useLinkThemeState'
import { usePageDisplayContext } from '../../../context/page-display'
import { useSetValueWithPreview } from '../utils'
import { useSubmitWidget } from '../submitUtils'
import { v4 as uuid } from 'uuid'
import Link from 'next/link'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'
import styled from 'styled-components'
import useSWR from 'swr'

const workerUrl = COMMERCE_WORKER_ENDPOINT

export const Form: React.FC<FormProps> = ({ order, curr, handleClose }: FormProps) => {
  const edit = Boolean(curr)

  useEffect(() => {
    if (!edit) {
      events.userClickedAddStripeCommerceLink()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { linkTheme, setLinkTheme } = useLinkThemeState({
    defaultLinkTheme: curr?.linkTheme ?? null
  })
  const { token } = useAuthState()
  const page = useCurrentPage()
  const authHeaders = useMemo(() => ({ headers: { Authorization: 'Bearer ' + token } }), [token])
  const { data: merchantAuthStatus } = useSWR(
    [workerUrl + '/merchantAuthStatus', token],
    async (url: string) => (await (await fetch(url, authHeaders)).json()) as MerchantAuthStatus
  )
  const isStripeAuthed = merchantAuthStatus?.autoTaxStatus && merchantAuthStatus?.signupStatus
  const { data: signupUrl } = useSWR(
    Boolean(merchantAuthStatus?.signupStatus) === false && page?.id
      ? [workerUrl + '/createAccountLink', token]
      : null,
    async (url: string) =>
      (
        await (
          await fetch(url, {
            method: 'POST',
            body: JSON.stringify({ pageId: page?.id }),
            ...authHeaders
          })
        ).json()
      ).onboardingUrl as string
  )
  const { data: products } = useSWR(
    isStripeAuthed === true ? [workerUrl + '/products', token] : null,
    async (url: string) =>
      (await (await fetch(url, authHeaders)).json()).products as StripeProduct[]
  )
  const productObj = useMemo(
    () =>
      products?.reduce(
        (acc, product) => ({ [product.id]: product, ...acc }),
        {} as Record<string, StripeProduct>
      ) ?? null,
    [products]
  )
  const actionData = getValidatedActionData<'productForSale'>(curr?.actionData, 'products')
  const [submitting, setSubmitting] = React.useState<boolean>(false)
  const {
    register,
    setValue: setFormValue,
    handleSubmit,
    errors,
    watch,
    setError,
    clearErrors
  } = useForm<ProductForSaleFormFields>({
    mode: 'onBlur',
    defaultValues: {
      activeProductIds: new Set(actionData?.products.map(({ id }) => id)),
      displayType: 'grid'
    }
  })
  React.useEffect(() => {
    register(CommerceFormFields.ACTIVE_PRODUCT_IDS, {
      validate: value => {
        return value.size > 0 || 'Please select at least one product'
      }
    })
    register(CommerceFormFields.DISPLAY_TYPE)
  }, [register])

  const id = curr ? curr.id : uuid()
  const submitWidget = useSubmitWidget()

  // Default values not working on first render for some reason
  const { activeProductIds, displayType } = watch(['activeProductIds', 'displayType'], {
    activeProductIds: new Set()
  })
  const onSubmit = async ({
    activeProductIds,
    displayType
  }: ProductForSaleFormFields): Promise<void> => {
    setSubmitting(true)
    if (productObj) {
      await submitWidget({
        curr,
        linkTheme,
        actionData: { products: Array.from(activeProductIds).map(id => productObj[id]) },
        widgetType: 'productForSale',
        fields: {
          id,
          order,
          displayType
        }
      })
    }
    setSubmitting(false)
    handleClose()
  }

  const disabled = !activeProductIds.size
  const previewLink = React.useMemo(
    () => ({
      ...defaultWidgetLink,
      type: 'productForSale',
      linkTheme,
      id,
      displayType: displayType,
      actionData: {
        products: productObj ? Array.from(activeProductIds).map(id => productObj[id]) : []
      }
    }),
    [activeProductIds, displayType, id, linkTheme, productObj]
  )
  const { setValue, setLinkThemeValue } = useSetValueWithPreview(
    previewLink,
    setFormValue,
    setLinkTheme
  )

  return (
    <>
      <StyledForm
        title={
          <FormTitle icon={<Marketplace />} title={`${edit ? 'Edit this' : 'Add a'} storefront`} />
        }
        onSubmit={handleSubmit(onSubmit)}
      >
        {merchantAuthStatus === undefined ? (
          <CenterBox flexDirection="column">
            <Spinner width="20px" height="20px" />
            <Spacer mb="4px" />
            <CenterBox>
              <Text>Connecting to</Text>
              <Spacer mr="4px" />
              <Image height="24px" src="/logos/stripe-font.svg" />
            </CenterBox>
          </CenterBox>
        ) : (
          <Box {...stopPropagationEvents} flexDirection={'column'}>
            <Text variant="checkbox/regular">
              {merchantAuthStatus !== undefined &&
                (merchantAuthStatus.signupStatus === true ? (
                  <Box>
                    {merchantAuthStatus.autoTaxStatus === true ? (
                      <>
                        <Image src={'/logos/stripe.svg'} />
                        <Spacer mr="8px" />
                        <Text variant="body/medium" color="secondary.disabled">
                          Stripe account authorized
                        </Text>
                      </>
                    ) : (
                      <>
                        <Image src={'/logos/stripe.svg'} />
                        <Spacer mr="8px" />
                        <Link
                          passHref
                          href="https://stripe.com/docs/connect/tax-reporting"
                          legacyBehavior
                        >
                          <Text
                            as="a"
                            target="_blank"
                            variant="body/medium"
                            color="primary.flowBlue"
                            textDecoration="underline"
                            cursor="pointer"
                          >
                            Click here for info on Stripe tax setup
                          </Text>
                        </Link>
                      </>
                    )}
                  </Box>
                ) : (
                  <ButtonLink
                    onClick={() => events.userClickedConnectWithStripe(page?.id ?? '')}
                    href={signupUrl ?? ''}
                    width="100%"
                    py="10px"
                    borderColor="primary.flowBlue"
                    bg="transparent"
                    px="24px"
                    height="auto"
                  >
                    <Image src={'/logos/stripe.svg'} />
                    <Spacer mr="8px" />
                    <Box alignItems="center">
                      <Text variant="button/general" color="primary.flowBlue">
                        Connect to
                      </Text>
                      <Spacer mr="4px" />
                      <Image height="24px" src="/logos/stripe-font.svg" />
                    </Box>
                  </ButtonLink>
                ))}
            </Text>
            <Spacer mb="8px" />
            <Text>Create an inventory</Text>
            <Spacer mb="4px" />
            <ProductSelector
              handleClick={id => {
                if (activeProductIds.has(id)) {
                  setValue(
                    CommerceFormFields.ACTIVE_PRODUCT_IDS,
                    activeProductIds.delete(id) && activeProductIds
                  )
                } else {
                  setValue(CommerceFormFields.ACTIVE_PRODUCT_IDS, activeProductIds.add(id))
                  clearErrors()
                }
              }}
              activeProductIds={activeProductIds}
              stripeProducts={products}
              disabled={isStripeAuthed === false}
            />
            <Spacer mb="8px" />
            <DisplayToggle
              onChange={(_e, type) => {
                setValue(CommerceFormFields.DISPLAY_TYPE, type)
              }}
              displayOptions={[
                { title: 'Grid', value: 'grid' },
                { title: 'Stacked', value: 'stacked' }
              ]}
              displayType={displayType}
              name="display-type-toggle"
              title="Display Type"
            />
            {errors.activeProductIds && (
              <Text color="status.errorDark">
                {(errors.activeProductIds as FieldError)?.message}
              </Text>
            )}

            <Spacer mb="16px" />
            <LinkStyleGated
              hiddenFields={{ textSize: true }}
              linkTheme={linkTheme}
              setLinkTheme={setLinkThemeValue}
            />
            <Spacer mb="16px" />

            <SettingsAndSaveLink disabled={disabled} curr={curr} handleClose={handleClose} />
          </Box>
        )}
      </StyledForm>
    </>
  )
}

const ProductCard: React.FC<
  StripeProduct & {
    image?: string
    preview?: boolean
    editLinkMode?: boolean
    isGrid: boolean
    styles: Omit<BoxProps, 'onClick' | 'children'>
    isLockedTemplateLink?: boolean
  }
> = ({
  name,
  description,
  isGrid,
  price: { currency, unitCostCents },
  preview,
  isLockedTemplateLink,
  styles,
  images,
  editLinkMode = false
}) => {
  const typedCurrency = currency in CURRENCIES ? (currency as keyof typeof CURRENCIES) : 'USD'
  const dollarPrice = toDollars(unitCostCents, typedCurrency)

  return (
    <Box
      m={isGrid ? null : '4px 0px'}
      bg={'primary.white'}
      boxShadow="float"
      width="100%"
      height="100%"
      borderRadius="12px"
      {...styles}
    >
      <Box position={'relative'} flexDirection="column" width="100%">
        {images?.[0] && (
          <Image
            alt={`${name} thumbnail`}
            padding={images?.[0] ? 0 : '30px'}
            width={'100%'}
            height={isGrid ? '164px' : 'initial'}
            bg={!images?.[0] ? 'secondary.backgroundDark' : null}
            objectFit="cover"
            borderRadius={'12px 12px 0px 0px'}
            src={images?.[0]}
            maxHeight={200}
          />
        )}
        <Box p="8px" flexDirection="column" height={'100%'}>
          <Text variant="body/medium" color="inherit" fontWeight="600">
            {name}
          </Text>
          <Spacer mb="8px" />
          <Text color="inherit">{description}</Text>
          <Spacer mb="auto" mt="8px" />
          <Text variant="body/small" color="inherit" fontWeight={'bold'}>
            {formatCurrency(dollarPrice, typedCurrency)}
          </Text>
        </Box>
        {editLinkMode && (
          <EditLinkCardEndAdornment
            isLockedTemplateLink={isLockedTemplateLink}
            wrapperWidth="5%"
            position="absolute"
            top="75%"
            right="4px"
            iconWidth="10px"
            iconMargin="auto"
          />
        )}
      </Box>
    </Box>
  )
}
const NoStyleLink = styled.a`
  color: inherit;
  text-decoration: inherit;
  width: 100%;
  img {
    object-fit: 'cover';
  }
`
export const Flowpage: React.FC<FlowpageProps> = ({
  link,
  preview,
  editLinkMode,
  isLockedTemplateLink
}: FlowpageProps) => {
  const {
    page: { slugName, id: pageId, displayName: pageDisplayName }
  } = usePageDisplayContext()
  const { theme } = useFlowpageTheme()
  const { products } = getValidatedActionDataOrThrow<'productForSale'>(link?.actionData, 'products')
  const childLinks = (link?.childLinks ?? []).map(child => child?.actionData as StripeProduct)
  const childLinkIds: string[] = (link?.childLinks ?? []).map(child => child?.id ?? '')
  const productMap = !preview ? childLinks : products
  const { trackLinkClick } = useFlowpageAnalyticsCollector({
    isPreview: Boolean(preview),
    isEditMode: Boolean(editLinkMode)
  })
  const getCheckoutUrl = useCallback(
    (productId: string) =>
      COMMERCE_WORKER_ENDPOINT +
      '/checkoutSession?' +
      stringify({
        pageId,
        productId,
        pageDisplayName,
        pageSlug: slugName
      }),
    [pageDisplayName, pageId, slugName]
  )
  const isGrid = link?.displayType === 'grid'

  const styles = getLinkCardStyles({
    theme,
    linkTheme: link.linkTheme
  })
  return (
    <Box
      flexDirection={isGrid ? 'row' : 'column'}
      flexWrap={isGrid ? 'wrap' : 'nowrap'}
      id={link.id}
      justifyContent={'space-between'}
      style={{ width: '100%' }}
    >
      {productMap?.map((product, idx) => {
        const childLinkId = !preview ? childLinkIds[idx] : link.id
        const parentLinkId = !preview ? link.id : undefined
        return (
          <Box
            m={isGrid ? '4px' : '4px 0px'}
            key={product.id + 'display'}
            width={isGrid ? 'calc(50% - 8px)' : '100%'}
            alignItems="stretch"
          >
            {preview ? (
              <ProductCard
                isGrid={isGrid}
                {...product}
                isLockedTemplateLink={isLockedTemplateLink}
                preview={preview}
                image={removeNull(link.thumbNailImgUrl)}
                styles={styles}
              />
            ) : (
              <NoStyleLink
                id={`hp-flowpage-link_card-${link.id}`}
                data-testid={`hp-flowpage-link_card-${link.id}`}
                target={'_blank'}
                rel="noopener"
                href={getCheckoutUrl(product.id)}
                data-link_id={link.id}
                data-widget="product-for-sale"
                onClick={() =>
                  trackLinkClick(
                    { link_id: childLinkId, parent_link_id: parentLinkId },
                    { useHeap: true }
                  )
                }
              >
                <ProductCard
                  isGrid={isGrid}
                  {...product}
                  isLockedTemplateLink={isLockedTemplateLink}
                  preview={preview}
                  image={removeNull(link.thumbNailImgUrl)}
                  editLinkMode={editLinkMode}
                  styles={styles}
                />
              </NoStyleLink>
            )}
          </Box>
        )
      })}
    </Box>
  )
}

export const Manager: React.FC<ManagerProps> = ({ editWidget, link, handle }: ManagerProps) => {
  return (
    <DefaultManager
      altTitle="Storefront"
      link={link}
      iconUrl={icons.product}
      editWidget={editWidget}
      handle={handle}
    />
  )
}
